import auth from './auth';
import { setUpdateCart, setGetCartProducts } from './cart';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  auth,
  setUpdateCart,
  setGetCartProducts,
  router: routerReducer
});