import React from "react";
import BlogPage from "../components/blog";

const Blog = () => {
  return (
    <>
      <BlogPage />
    </>
  );
};

export default Blog;
