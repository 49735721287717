import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import "./style.scss";
import HeaderLogo from "../../images/zamzam-logo.png";
import { FaBars, FaRegHeart, FaShoppingCart, FaUserAlt } from "react-icons/fa";
import { VscChromeClose, VscMenu } from "react-icons/vsc";
// import { setCartTotal } from "../../utils/utils"
import { isLoggedIn } from "../../common/Auth";
import { updateCartQuantity, getCartProducts } from "../../actions/cart";
import { getAuthUser, getAccessToken } from "../../common/Auth";
import { httpService } from "../../service/http";
// InitialPopup
import InitialPopup from "../others/InitialPopup";

const Header = () => {
  const dispatch = useDispatch();
  const {product, quantity, totalQuantity} = useSelector(state => state.setUpdateCart)

  // const handleBeforeUnload = (event) => {
  //     localStorage.setItem("showLoader", 'true');
  //     // alert("Are you sure?")
  // };

  const getCartData = () => {
    (async () => {
      try {
        let user = getAuthUser();
        if(!user) {
          return;
        }
        const { data } = await httpService.get(`/user/cart?user_id=${user.userId}`,
        {headers: {'Authorization': getAccessToken()}});
        const totalNumbers = data?.data.reduce((accumulator, item) => accumulator + item.item_quantity, 0);
        dispatch(updateCartQuantity(totalNumbers));
        dispatch(getCartProducts(data?.data))
      } catch (error) {
        console.log(error)
      }
    })()
  }
  useEffect(()=> {
    // setCartTotal();
    // window.addEventListener("beforeunload", handleBeforeUnload);
    // return () => {
    //     window.removeEventListener("beforeunload", handleBeforeUnload);
    // };
    getCartData()
  },[])

  const getAuthBasedUrl = (url) => {
    if (isLoggedIn()) {
      return url
    }
    return `/login?redirect=${url}`
  }

  const [mobileMenu, setMobileMenu] = useState(false);
  // console.log(totalQuantity);
  let user = getAuthUser();
  return (
    <>
      <header>
        <InitialPopup />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="header-block">
                <div className="brand-logo">
                  <Link to="/">
                    <img
                      src={HeaderLogo}
                      alt={"HeaderLogo"}
                      width="130px"
                      height="55px"
                    />
                  </Link>
                </div>
                <ul
                  className={`header-menu main-menus ${
                    mobileMenu && "mobile-open"
                  }`}
                >
                  <li
                    className="close-btn"
                    onClick={() => setMobileMenu(!mobileMenu)}
                  >
                    <span>
                      <VscChromeClose />
                    </span>
                  </li>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/sweets">Sweets</Link>
                  </li>
                  <li>
                    <Link to="/savouries">Savouries</Link>
                  </li>
                  <li>
                    <Link to="/aboutus">About us</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contactus">Contact</Link>
                  </li>
                  {/* <li>
                    <Link to="/allproduct">All Product</Link>
                  </li> */}
                </ul>
                <ul className="header-menu">
                  <li className="dropdown-wrp">
                    <Link to="#">
                      <FaUserAlt />{" "}
                    </Link>
                    <div className="hd-dropdown">
                      <ul>
                        {user && 
                          <li> <Link to={getAuthBasedUrl("/profile")}>Profile</Link> </li>
                        }
                        {user && 
                          <li> <Link to={getAuthBasedUrl("/orders")}>Orders</Link> </li>
                        }
                        <li>
                          {user ? <Link to="/logout">Logout</Link> : <Link to="/login">Login</Link> }
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <Link to={getAuthBasedUrl("/favourites")}>
                      <FaRegHeart />
                    </Link>
                  </li>
                  <li>
                    <Link to="/cart" className="cart-wrp">
                      <FaShoppingCart />
                      <span id="cart-circle-wrapper">
                      {parseInt(quantity) > 0 && <span className="cart-circle">{quantity}</span>}
                      </span>
                    </Link>
                  </li>
                  <li
                    className="menu-icon"
                    onClick={() => setMobileMenu(!mobileMenu)}
                  >
                    <FaBars />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
