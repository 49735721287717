import React from "react";
import ComingSoonPage from "../components/comingsoon";

const ComingSoon = () => {
  return (
    <>
      <ComingSoonPage />
    </>
  );
};

export default ComingSoon;
