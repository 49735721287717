import React, { useEffect, useState } from "react";
import popupBG from "../../images/init-popup.png";

import LoadingBallImg from "../../images/ripple-ani.svg";
import "./others.scss";
const PageLoading = () => {
  // const [showFirstLoader, setShowFirstLoader] = useState(localStorage.getItem('showFirstLoader'))
  // useEffect(()=> {
  //   setTimeout(() => {
  //     localStorage.removeItem('showFirstLoader')
  //   }, 2000)
  // },[])
  // console.log("Showing First Loader", showFirstLoader)
  return (
    <div className="loading-wrp">
      <img src={LoadingBallImg} className="popupbg" alt="popup" />
    </div>
  );
};

export default PageLoading;
