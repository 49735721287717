// import { configureStore } from '@reduxjs/toolkit'
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { thunk } from "redux-thunk";
// import { createLogger } from 'redux-logger'
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { promiseMiddleware, localStorageMiddleware } from './middleware';
import reducer from './reducers/index';
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
const middlewares = [thunk];


const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware, ...middlewares);
  } else {
    // Enable additional logging in non-production environments.
    // return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware, ...middlewares, createLogger())
    return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware, ...middlewares)
  }
};

// export default configureStore({
//     reducer,
//     reducer: getMiddleware(),
// })

// import { routerMiddleware } from 'react-router-redux'
// // import createHistory from 'history/createBrowserHistory';
// import { createBrowserHistory } from "history";


// export const history = createBrowserHistory();

// // Build the middleware for intercepting and dispatching navigation actions
// const myRouterMiddleware = routerMiddleware(history);

// const getMiddleware = () => {
//   if (process.env.NODE_ENV === 'production') {
//     return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware);
//   } else {
//     // Enable additional logging in non-production environments.
//     return applyMiddleware(myRouterMiddleware, promiseMiddleware, localStorageMiddleware, createLogger())
//   }
// };

export const store = createStore(
  reducer, getMiddleware()
);