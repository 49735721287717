import React, { Suspense, lazy } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "./stylesheet/style.scss";
import PageLoading from "./components/others/loading";
import ComingSoon from "./pages/comingsoon";
import Blog from "./pages/blog";
const Home = lazy(() => import("./pages/home"));
const ProductList = lazy(() => import("./pages/product-list"));
// const ProductListPage = lazy(() => import("./components/product-list"))
const Cart = lazy(() => import("./pages/cart"));
const ProductDetailPage = lazy(() => import("./pages/product-detail"));
const LoginPage = lazy(() => import("./pages/login"));
const LogoutPage = lazy(() => import("./pages/logout"));
const RegisterPage = lazy(() => import("./pages/register"));
const TermsPageDetail = lazy(() => import("./pages/terms"));
const AboutUSDetail = lazy(() => import("./pages/aboutus"));
const ContactUsDetail = lazy(() => import("./pages/contactus"));
const AllProductPageDetails = lazy(() => import("./pages/allproduct"));
const Favourites = lazy(() => import("./pages/favourites"));
const PolicyDetail = lazy(() => import("./pages/policy"));
const Checkout = lazy(() => import("./pages/checkout"));
const Profile = lazy(() => import("./pages/profile"));

const OrderSuccess = lazy(() => import("./pages/orders/ordersuccess"));
const OrderStatus = lazy(() => import("./pages/orders/orderstatus"));
const OrderFailed = lazy(() => import("./pages/orders/orderfailed"));
const Orders = lazy(() => import("./pages/orders/orders"));



function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Router basename="/">
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/sweets" exact element={<ProductList />}></Route>
          <Route path="/savouries" exact element={<ProductList />}></Route>
          <Route path="/cart" exact element={<Cart />}></Route>
          <Route path="/login" exact element={<LoginPage />}></Route>
          <Route path="/register" exact element={<RegisterPage />}></Route>
          <Route path="/terms" exact element={<TermsPageDetail />}></Route>
          <Route path="/aboutus" exact element={<AboutUSDetail />}></Route>
          <Route path="/blog" exact element={<Blog />}></Route>
          <Route path="/contactus" exact element={<ContactUsDetail />}></Route>
          <Route path="/allproduct" exact element={<AllProductPageDetails />}></Route>
          <Route path="/favourites" exact element={<Favourites />}></Route>
          <Route path="/checkout" exact element={<Checkout />}></Route>
          <Route path="/profile" exact element={<Profile />}></Route>

          <Route path="/order/status/:transactionid" exact element={<OrderStatus />}></Route>
          <Route path="/order/success" exact element={<OrderSuccess />}></Route>
          <Route path="/order/failed" exact element={<OrderFailed />}></Route>
          <Route path="/orders" exact element={<Orders />}></Route>

          <Route path="/logout" exact element={<LogoutPage />}></Route>
          <Route path="/coming-soon" exact element={<ComingSoon />}></Route>
          <Route
            path="/policies"
            exact
            element={<PolicyDetail />}
          ></Route>

          <Route
            path="/product-detail/:category/:id"
            exact
            element={<ProductDetailPage />}
          ></Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
