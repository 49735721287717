export const getAuthUser = () => {
    let authUser = localStorage.getItem('auth')
    if(authUser) {
        return JSON.parse(authUser)
    }
}

export const getAccessToken = () => {
    let authUser = localStorage.getItem('auth')
    if(authUser) {
        return JSON.parse(authUser).token
    }
}

export const isLoggedIn = () => {
    let authUser = localStorage.getItem('auth')
    if(authUser) {
        return true;
    }
    return false;
}


export const logout = () => {
    let authUser = localStorage.getItem('auth')
    if(authUser) {
        localStorage.removeItem('auth')
    }
}