import React from "react";
import "./style.scss";
import comingsoonIc from "../../images/comingsoon.png";

import { useNavigate } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";

const BlogPage = () => {

    let navigate = useNavigate(); 
    const redirectHome = () =>{ 
        let path = `/`; 
        navigate(path);
    }
    
  return (
   
    <>
     <Header />
      <section className="loginpage">
      <article>
            <img src={comingsoonIc} alt="coming soon" className="soonicon" />
          <h4>Blog Page Coming Soon</h4>
          <button className="login-btn" type="submit" onClick={redirectHome}>HOME</button>
        </article>
      </section>
      
      <Footer />
    </>
  );
};

export default BlogPage;
