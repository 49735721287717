export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const UPDATE_CART_ITEM_QUANTITY = 'UPDATE_CART_ITEM_QUANTITY';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_CART_DATA = "UPDATE_CART_DATA";
export const GET_CART_ITEM_PRODUCTS = "GET_CART_ITEM_PRODUCTS";

export const updateCartItem = item => ({
    type: UPDATE_CART_ITEM,
    item
})

export const updateCartItemQuantity = (quantity) => ({
    type: UPDATE_CART_ITEM_QUANTITY,
    quantity
})

export const getCartItemProducts = (products) => ({
    type: GET_CART_ITEM_PRODUCTS,
    products
})

export const clearCart = () => ({
    type: CLEAR_CART,
})

// import toastr from 'toastr';

// import { login as authLogin } from '../middleware/Authentication';
// import Service from "../utility/Service"

// import { Locale } from "../locale/index";
// import Env from "../utility/env";

// export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
// export const LOGIN_FAILURE = "LOGIN_FAILURE";
// export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
// export const LOGIN_REQUEST = "LOGIN_REQUEST";

// // import Toaster from '../views/notifications/toaster/Toaster';


//  export const loginRequest = (data) => ({
//     type: LOGIN_REQUEST,
//     userData: data
// });

// export const loginSuccess = (data) => ({
//     type: LOGIN_SUCCESS,
//     userData: data
// });

// export const loginFailure = (data) => ({
//     type: LOGIN_FAILURE,
//     userData: data
// });


export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";

// import Toaster from '../views/notifications/toaster/Toaster';


 export const addToCartRequest = () => ({
    type: ADD_TO_CART_REQUEST,
    product: {},
    quantity: 0
});

export const addToCartSuccess = (product, qty) => ({
    type: ADD_TO_CART_SUCCESS,
    product: product,
    quantity: qty
});

export const addToCartFailure = () => ({
    type: ADD_TO_CART_FAILURE,
    product: {},
    quantity: 0
});

export const addToCartAction = (product, quantity) => (dispatch, getState) => {
    // console.log(product)
    // console.log(quantity)
    dispatch(updateCartItemQuantity(product, quantity))
    // toastr.remove()
    // Service.post("auth/login", fields,
    //     (response) => {
    //         if (response && response.status && response.status === 200) {
    //             toastr.success(response.message ? response.message : 'Logged In');
    //             authLogin(response.data)
    //             dispatch(loginSuccess(response.data));
    //         } else {
    //             toastr.error(response && response.message && response.message.non_field_errors ? response.message.non_field_errors.join() : response && response.message ? response.message : 'Something Went Wrong');
    //         }
    //     }
    // )
};

export const updateCartQuantity = (quantity) => (dispatch, getState) => {
    dispatch(updateCartItemQuantity(quantity))
};


export const getCartProducts = (products) => (dispatch, getState) => {
    dispatch(getCartItemProducts(products))
};