import { 
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_REQUEST,
  CLEAR_CART,
  UPDATE_CART_ITEM,
  UPDATE_CART_DATA,
  UPDATE_CART_ITEM_QUANTITY,
  GET_CART_ITEM_PRODUCTS
} from "../actions/cart";

import { addToCartHandler } from "../utils/utils";

export const setUpdateCart = (state = { product: {}, quantity: 0, totalQuantity: 0 }, action) => {
  switch (action.type) {
      case UPDATE_CART_ITEM_QUANTITY:
        // let cart_data = JSON.parse(localStorage.getItem("cart_items")) || [];
        // console.log(cart_data)
        // const result = cart_data.some(item => item.id === action.product.id)
        // if(result) {
        //     for (let i = 0; i < cart_data.length; i++) {
        //         if(cart_data[i].id === action.product.id) {
        //             cart_data[i].quantity = parseInt(cart_data[i].quantity) + parseInt(action.quantity)
        //         }
        //     }
        // } else {
        //     cart_data.push(action.product);
        // }
        // // console.log(cart_data)
        // const totalNumbers = cart_data.reduce((accumulator, item) => {
        //   return accumulator + item.quantity
        // }, 0);
        // // let totalNumbers = 0;
        // // cart_data.forEach((item) => totalNumbers += item.quantity);
        // // console.log(totalNumbers)
        // localStorage.setItem("cart_items", JSON.stringify(cart_data));
        // localStorage.setItem('cart_quantity', totalNumbers);
        // action.totalQuantity = totalNumbers;
        // return Object.assign({}, action);
        return Object.assign({}, action);
      case UPDATE_CART_DATA:
          return Object.assign({}, action);
      // case ADD_TO_CART_SUCCESS:
      //     return Object.assign({}, action);
      // case ADD_TO_CART_FAILURE:
      //     return Object.assign({}, action);
      // case ADD_TO_CART_REQUEST:
      //     return Object.assign({}, action);
      default:
          return state;
  }
};


export const setGetCartProducts = (state = { products: []}, action) => {
  switch (action.type) {
      case GET_CART_ITEM_PRODUCTS:
        return Object.assign({}, action);
      default:
          return state;
  }
};


// export const cartReducer = (state = { items: [] }, action) => {
//   switch (action.type) {
//       case CLEAR_CART:
//           return { items: [] };
//       case UPDATE_CART_ITEM:
//           const item = action.item;
//           if (item && item.quantity > 0) {
//               const index = state.items.findIndex(it => it.product._id === item.product._id);
//               const newItems = [...state.items];
//               if (index !== -1) {
//                   newItems[index] = { ...item };
//                   return { ...state, items: newItems };
//               } else {
//                   newItems.push({ ...item });
//               }
//               return { ...state, items: newItems };
//           } else {
//               const items = state.items.filter(it => it.product._id !== item.product._id);
//               return { ...state, items };
//           }

//       case UPDATE_CART_ITEM_QUANTITY:
//           const quantity = action.quantity;
//           const product = action.product;

//           if (quantity === 0) {
//               const items = state.items.filter(it => it.product._id !== product._id);
//               return { ...state, items };
//           } else {
//               const index = state.items.findIndex(it => it.product._id === product._id);
//               if (index !== -1) {
//                   const newItems = [...state.items];
//                   const item = { ...state.items[index], quantity };
//                   newItems[index] = { ...item };
//                   return { ...state, items: newItems };
//               } else {
//                   // pass, should never happen
//               }
//           }
//           return state;

//       default:
//           return state;
//   }
// };