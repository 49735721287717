import React, { useEffect, useState } from "react";
import popupBG from "../../images/init-popup.png";

import "./others.scss";
const InitialPopup = () => {
  const [showFirstLoader, setShowFirstLoader] = useState(false)
  useEffect(()=> {
    if(!localStorage.getItem('initialPopup')) {
      setShowFirstLoader(true); 
      document.documentElement.classList.add('no-overflow');
    }
  },[])

  const closePopup = () => {
    setShowFirstLoader(false)
    localStorage.setItem('initialPopup', true)
    document.documentElement.classList.remove('no-overflow');
  }
  return (
    <div className={`${showFirstLoader ? 'init-popup-top open' : 'init-popup-top'}`} >
      <div className="init-popup-wrp">
        <img src={popupBG} className="popupbg" alt="popup" />
        <div className="init-popup-in">
        <span>SWEETS</span>
          <p>Step into our sweet haven, and you'll be greeted with an array of delightful choices that cater to every palate. From the classics like Gulab Jamun and Jalebi to regional specialties like Rasgulla from West Bengal, Mysore Pak from South India, and Sandesh from Gujarat, our shelves are stocked with a wide variety of Indian sweets that will leave you spoilt for choice.</p>
          <button onClick={() => closePopup()}>Order Now</button>
        </div>
      </div>
    </div>
  );
};

export default InitialPopup;
